<template>
  <section>
    <FormulateForm
      ref="eventForm"
      name="eventForm"
    >
      <b-card-actions
        :show-loading="loading"
        action-collapse
        :title="`${$t('Dados do evento')}${event.id ? ` #${event.id}` : ''}`"
      >
        <b-row>
          <b-col md="4">
            <e-store-combo
              id="store_id"
              v-model="event.storeId"
              :required="true"
              :clearable="false"
              :disabled="isReadOnly || isEdit"
              @input="onSelectStore"
            />
          </b-col>
          <b-col md="4">
            <FormulateInput
              id="event_name"
              v-model="event.name"
              :type="isReadOnly ? 'label' : 'text'"
              :label="$t('Nome do evento')"
              class="required"
              validation="required"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="price_table"
              v-model="event.priceTableId"
              :type="isReadOnly ? 'label' : 'vue-select'"
              :label="$t('Tabela de preço')"
              :options="getComboPriceTables"
              class="required"
              validation="required"
            />
          </b-col>
        </b-row>
      </b-card-actions>

      <b-card-actions
        :show-loading="loading || loadingProducts"
        action-collapse
        :title="$t('Produtos')"
      >
        <b-row>
          <b-col
            v-if="!isReadOnly"
            md="12"
            class="mb-1 d-flex justify-content-end"
          >
            <e-button
              :text="$t('Adicionar produto')"
              variant="primary"
              @click="() => showProductSidebar()"
            />
          </b-col>

          <b-col md="12">
            <b-table
              show-empty
              responsive
              striped
              class="bordered"
              :empty-text="$t('Nenhum produto adicionado')"
              :fields="skusFields"
              :items="getSkus"
              details-td-class="py-0 px-1"
            >
              <template #cell(action)="row">
                <e-grid-actions
                  :show-update="true"
                  :show-delete="true"
                  @update="showProductSidebar(row.item)"
                  @delete="onRemoveProduct(row)"
                />
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-card-actions>
    </FormulateForm>

    <div class="pb-1" />

    <event-product-sidebar
      v-if="event.storeId"
      ref="productSidebar"
      :store-id="event.storeId"
      :price-table-id="event.priceTableId"
      @update="onUpdateProduct"
      @add="onAddProduct"
    />

    <fab
      :main-tooltip="$t('Ações')"
      main-icon="keyboard_command_key"
      :actions="actions"
      :fixed-tooltip="true"
      :busy="loading"
      @save="saveEvent"
      @cancel-event="onCancel"
      @back="back"
    />
  </section>
</template>

<script>
import { BRow, BCol, BTable } from 'bootstrap-vue'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import { deliveryDomain, discountTypes, loadingOverlay } from '@/mixins'
import { EButton, EStoreCombo, EGridActions } from '@/views/components'
import Fab from '@/views/components/FAB.vue'
import EventProductSidebar from './components/EventProductSidebar.vue'

export default {
  name: 'EventMaintain',
  components: {
    BTable,
    BRow,
    BCol,
    BCardActions,
    EButton,
    EventProductSidebar,
    Fab,
    EStoreCombo,
    EGridActions,
  },

  mixins: [discountTypes, deliveryDomain, loadingOverlay],

  data() {
    return {
      loading: false,
      loadingProducts: false,
      isToUpdatePrice: true,
    }
  },

  computed: {
    ...mapState('pages/sale/event/eventMaintain', ['event']),
    ...mapGetters('pages/sale/event/eventMaintain', ['getSkus']),
    ...mapGetters('common/priceTables', ['getComboPriceTables']),

    skusFields() {
      return [
        {
          label: this.$t('Ações'),
          key: 'action',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
          hide: this.isReadOnly,
        },
        {
          label: this.$t('Produto'),
          key: 'sku',
          thClass: 'text-center',
          tdClass: 'text-left',
          formatter: val => val.name,
        },
        {
          label: this.$t('Qtd.'),
          key: 'quantity',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '60px' },
        },
      ].filter(c => !c.hide)
    },

    isEdit() {
      return !!this.$route.params.id
    },

    isReadOnly() {
      return !!this.$route.name.includes('read-only')
    },

    customerDefaultOptions() {
      const customer = this.event?.customer
      if (customer && Object.keys(customer).length > 0) return [customer]
      return []
    },

    actions() {
      return [
        {
          name: 'back',
          icon: 'arrow_back',
          color: 'red',
          tooltip: this.$t('Voltar'),
          show: true,
        },
        {
          name: 'save',
          icon: 'save',
          color: 'green',
          tooltip: this.$t('Salvar'),
          show: !this.isReadOnly,
        },
        {
          name: 'cancel-event',
          icon: 'cancel',
          color: 'red',
          tooltip: this.$t('Cancelar Evento'),
          show: this.isReadOnly,
        },
      ].filter(action => action.show)
    },
  },

  async mounted() {
    this.fetchPriceTables()
    if (this.isEdit) {
      try {
        this.isToUpdatePrice = false
        this.loading = true
        await this.fetchEventById(this.$route.params.id)
      } catch (err) {
        this.showError({ message: err.message })
      } finally {
        this.loading = false
        this.isToUpdatePrice = true
      }
    }
  },

  methods: {
    ...mapActions('common/priceTables', ['fetchPriceTables']),
    ...mapActions('pages/sale/event/eventMaintain', {
      fetchEventById: 'fetchEventById',
      stSaveEvent: 'saveEvent',
      stCleanState: 'cleanState',
      stSetEventStoreId: 'setEventStoreId',
      addProduct: 'addProduct',
      updateProduct: 'updateProduct',
      removeProduct: 'removeProduct',
    }),
    ...mapActions('pages/sale/order/orderMaintain', {
      stSetIsEventReceived: 'setIsEventReceived',
    }),

    showProductSidebar(item) {
      this.$refs.productSidebar.show(item)
    },

    async onAddProduct(formData) {
      await this.addProduct({ formData })
    },
    onUpdateProduct(formData) {
      this.updateProduct({ formData })
    },
    async onRemoveProduct(row) {
      const confirmed = await this.confirm()
      if (confirmed) {
        try {
          this.loading = true
          await this.removeProduct(row.index)
          this.showSuccess({
            message: this.$t('Exclusão realizada com sucesso'),
          })
        } catch (err) {
          this.showError({ error: err })
        } finally {
          this.loading = false
        }
      }
    },

    async saveEvent() {
      this.$refs.eventForm.showErrors()
      if (this.$refs.eventForm.hasErrors) {
        this.showInvalidDataMessage()
        return
      }

      try {
        this.loading = true
        await this.stSaveEvent()
        this.showSuccess({
          message: this.$t(`${this.isEdit ? 'Atualização' : 'Inclusão'} realizada com sucesso`),
        })
        this.stCleanState()
        this.$router.push({ name: 'event-list' })
      } catch (err) {
        this.showError({ error: err })
      } finally {
        this.loading = false
      }
    },

    async onCancel() {
      const { id } = this.event
      try {
        const confirm = await this.confirm()
        if (confirm) {
          this.fetching = true
          await this.$http.delete(`/api/sales/events/${id}`)
          this.stCleanState()
          this.$router.push({ name: 'event-list' })
          this.showSuccess({
            message: this.$t('Evento cancelada com sucesso.'),
          })
        }
      } catch (error) {
        this.showError({ error })
      } finally {
        this.fetching = false
      }
    },

    async back() {
      this.stCleanState()
      this.$router.push({ name: 'event-list' })
    },

    async onCreateOrder() {
      this.stSetIsEventReceived(true)
      this.$router.push({ name: 'order-add' })
    },

    onUpdateDelivery() {
      if (this.isReadOnly || this.loading) return
      this.stUpdateDeliveryTax()
    },

    async onSelectStore(storeId) {
      if (!storeId || this.loading) return

      const oldStoreId = this.event.storeId
      if (oldStoreId && storeId !== oldStoreId) {
        const confirmed = await this.confirm({
          text: this.$t(
            'Ao alterar a loja, os valores de delivery e produtos podem ser atualizados e os campos que precisam de autorização terão valor e autorização resetados.'
          ),
        })

        if (!confirmed) {
          // necessário para forçar o combo a alterar o valor.
          await this.stSetEventStoreId({ storeId: null })
          await this.stSetEventStoreId({ storeId: oldStoreId })
          return
        }
        await this.stCleanInputsAuth()
      }

      await this.stSetEventStoreId({ storeId })
      // await this.stFetchStoreById({ storeId })
      // await this.onUpdateDelivery()
      // this.updatePrices()
    },

    onChangeDeliveryFeeType(val) {
      if (!val) return
      this.onUpdateDelivery()
    },
    onChangeDelivery(val) {
      if (val) this.onUpdateDelivery()
    },
    onChangeConsign(val) {
      if (val) this.onUpdateDelivery()
    },

    changeAddress(addressData) {
      // TODO buscar taxa de convêniencia
      if (addressData) this.stSetDeliveryAddress({ addressData })
      else this.stCleanDeliveryAddress()
    },
  },
}
</script>
